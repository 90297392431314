import styled from "styled-components";

export const ActionButton = styled.button`
  font-family: "Assistant";
  color: white;
  border: 0;
  border-radius: 20px;
  padding: 0 2.5rem;
  height: 100%;
  font-weight: 700;
  font-size: 16px;
  margin-right: 1rem;
  cursor: pointer;

  outline: none;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.08),
      0px 1px 10px rgba(0, 0, 0, 0.08);
    outline: none;
  }
  &.main {
    background: #0059f0;
    &:hover {
      background: rgb(0, 62, 168);
    }
  }
  &.outline {
    background: transparent;
    border: 1px solid white;
    &:hover {
      border: 1px solid #0059f0;
    }
  }
  &.second {
    background: white;
    color: #0059f0;
    border: 1px solid #0059f0;
    &:hover {
      background: #ffffffd1;
    }
  }
  &.p-5 {
    padding: 0.5rem 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 0.75rem;
    height: unset;
    margin: 0;
    margin-left: 0.5rem;
  }
`;
