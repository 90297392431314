import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HeaderComponent } from "../components/Header";
import styled from "styled-components";
import Cover from "../images/about_cover.png";

import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";

import { ActionButton } from "../sharedStyle";

// assets
import Location from "../images/about_page/location.svg";
import Arrow from "../images/about_page/arrow.svg";
import Clock from "../images/about_page/clock.svg";
import Dock from "../images/about_page/dock.svg";
import Finger from "../images/about_page/finger.svg";
import Handcuf from "../images/about_page/handcuf.svg";
import Truck from "../images/about_page/truck.svg";
import Sivan from "../images/about_page/sivan.svg";

export const E404 = () => {
  const [isScrolled, setScrolled] = useState(false);
  const [index, setIndex] = useState(0);
  let Carousel = null;
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", function (e) {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <div className="flex flex-col">
      <HeaderComponent isScrolled={true} />
      <div
        className="w-full relative "
        style={{ direction: "rtl", display: "grid" }}
      >
        <img
          className="w-full relative z-10"
          src={Cover}
          alt="cover"
          style={{ gridColumn: 1, gridRow: 1 }}
        />
        <h1
          style={{ gridColumn: 1, gridRow: 1 }}
          className="text-white text-3xl md:text-6xl relative transform translate-y-1/3 z-20 w-4/5 mx-auto font-bold mr-4 mt-0 md:mt-4"
        >
          הדף בדרך...
        </h1>
      </div>
      <div
        className="relative flex flex-col  w-4/5 mx-auto py-4 md:py-12"
        style={{ direction: "rtl" }}
      >
        <h1 className="text-3xl my-2 md:my-4 md:text-5xl font-semibold">
          בקרוב נתראה עם מערכת חדשה
        </h1>
        <p className="text-lg md:text-2xl my-2">
          אנחנו עובדים על שדרוג המערכת והאתר יעלה חזרה בקרוב. יש למה לצפות !!
        </p>
      </div>
      <div style={{ direction: "rtl", background: "#0059F0" }} className="">
        <div
          className="relative w-4/5 mx-auto text-center text-white py-12"
          style={{ direction: "rtl", background: "#0059F0" }}
        >
          <h2 className="text-lg">לקוח\ספק. עולם הלוגיסטיקה משתנה.</h2>
          <h1 className="text-4xl md:text-6xl my-2 md:mb-4 md:mt-0">
            יש משלוח - לוחצים על הכפתור - מגיע מוביל - ואתה כבר מתכונן למשלוח
            הבא. סטארט אפ!!!
          </h1>
        </div>
      </div>
      <div style={{ direction: "rtl", background: "#FAFAFA" }}>
        <div
          className="relative flex flex-col text-center justify-center  w-4/5 mx-auto py-4 md:py-12"
          style={{ direction: "rtl" }}
        >
          <h1 className="font-semibold mt-4 mb-2 text-4xl">
            TRUCKIBY מחברת בין שני העולמות
          </h1>
          <div className="text-lg">
            בעלי המשאיות -<b className="ml-1"> המובילים </b>
            ומזמיני ההובלות -<b> הספקים. </b>
            <br />
            חשבנו על כל מה שעשוי להפוך את חווית השירות והרווחים שלהם לטובים
            ונוחים יותר.
          </div>
          <div className="flex flex-wrap m-auto justify-center">
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Clock} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                הובלות מהיום להיום.
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Arrow} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                מחירים שוברי שוק, עד 50% פחות מהמקובל
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Finger} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                הזמנה או קבלת הובלה בקליק דרך הנייד
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Location} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                קבלת קריאות והזמנות על פי מיקום גאוגרפי ומסלול
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Dock} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                מפרט מלא עבור המוביל אודות סוג ההובלה המתבקשת
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Handcuf} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                אתם לא כבולים יותר למוביל מסוים אחד
              </label>
            </div>
            <div className="flex flex-col p-2 m-8 md:m-12 w-44">
              <img src={Truck} alt="" className="mb-4" />
              <label className="" style={{ color: "#0059F0" }}>
                אין יותר ״הובלות מיוחדות״ יש תמיד מוביל פנוי
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-4/5 mx-auto py-4" style={{ direction: "rtl" }}>
        <h1 className="font-semibold text-3xl mt-4 mb-2 md:text-4xl">
          היזם מאחורי TRUCKIBY
        </h1>
        <div className="flex flex-col md:flex-row ">
          <img src={Sivan} className="" alt="" />
          <div className="p-4 mx-4">
            <h2 className="text-xl md:text-2xl">
              <b className="font-semibold">סיוון מרון</b>, מייסד ומנהל תפעול
            </h2>
            <p className="my-3 text-lg">
              בעל ניסיון של 25 שנה במכירות מעולם הלוגיסטיקה, ביניהם: ספנות,
              חברות שילוח , חברות להובלה יבשתית
              <br />
              ובן למשפחת שמרלינג - חברת עמילות המכס הראשונה בישראל.
              <br />
              בשנתיים שקדמו להקמת TRUCKIBY ניהל את המכירות בחברת סטארטאפ לתחום
              הבתים החכמים.החיבור בין עולם הלוגיסטיקה לעולם הטכנולוגיה היה הזרז
              להקמת TRUCKIBY.
            </p>
          </div>
        </div>
      </div>
      <div style={{ direction: "rtl", background: "#0059F0" }} className="">
        <div className="py-8 text-white text-center w-4/5 mx-auto">
          <h3 className="text-lg">
            עכשיו כשהבנתם את גודל הבשורה - לא תיקחו בה חלק ? <br />
            אנו מזמינים אותכם להנות מהמהפכה החדשה בעולם ההובלות.
          </h3>
          <h1 className="font-semibold text-4xl mb-4">
            TRUCKIBY משנעים את עולם ההובלות
          </h1>
          <a href="/404?https://app.truckiby.com">
            <ActionButton
              onClick={() =>
                (window.location.href = "/404?https://app.truckiby.com/")
              }
              className="second "
              style={{ padding: ".5rem 1rem ", outline: "none" }}
            >
              להזמנת הובלה עכשיו!
            </ActionButton>
          </a>
        </div>
      </div>
      <footer className="bg-truckiby_black  px-8 flex p-4 md:p-6 justify-center items-center flex-col md:flex-row md:justify-between">
        <div className="flex">
          <a href="https://www.facebook.com/Truckiby-101427838023945">
            <img src={facebook} className="mx-2" />
          </a>
          <a href="https://www.linkedin.com/company/truckiby/about/">
            <img src={linkedin} className="mx-2" />
          </a>
        </div>
        <div className="text-white text-md md:text-lg mt-2 md:mt-0">
          <span className="font-semibold">2020 כל הזכויות שמורות</span>
          <span className="mx-1 font-bold">TRUCKIBY</span>
          <span className="font-semibold">©</span>
        </div>
      </footer>
    </div>
  );
};
