import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { MdChevronRight, MdChevronLeft, MdPhone } from "react-icons/md";
import { HeaderComponent } from "../components/Header";
import { ActionButton } from "../sharedStyle";

import image1 from "../images/slide-image1.png";
import image2 from "../images/slide-image2.png";
import image3 from "../images/slide-image3.png";

import part1 from "../images/illu01.png";
import part2 from "../images/illu02.png";
import part3 from "../images/illu03.png";
import part4 from "../images/illu04.png";

import cat1 from "../images/cat_1.png";
import price from "../images/prices.png";
import delievryTruck from "../images/delievry_truck.png";
import trolley from "../images/trolley.png";

import cat2 from "../images/cat_2.png";
import trucking from "../images/trucking.png";
import location from "../images/location.png";
import chat from "../images/chat.png";

import forest from "../images/forest.png";

import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";

import partner1 from "../images/partners/cosco.png";
import partner2 from "../images/partners/eden.png";
import partner3 from "../images/partners/Group.png";
import partner4 from "../images/partners/ISFAR.png";
import partner5 from "../images/partners/ravidion.png";
import partner6 from "../images/partners/supergum.png";
import { Slider } from "../components/Slider";
import { ContactUs } from "../components/ContactUs";

const Partners = styled.section`
  .alice-carousel__wrapper {
    height: 500px;
  }
  .alice-carousel__stage-item {
    height: 500px;
  }
  .alice-carousel__dots {
    display: none;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  .alice-carousel__stage {
    height: 90vh;
  }
  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    position: absolute;
    top: 50%;
    width: auto !important;
    font-size: 100px;
    transform: translate(0, -50%);
  }
  .alice-carousel__prev-btn {
    left: 0;
  }
  .alice-carousel__next-btn {
    right: 0;
  }
  .alice-carousel__dots {
    padding: 0;
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .alice-carousel {
    position: relative;
    top: -85px;
    width: 100%;
    margin: auto;
    direction: ltr;
  }
`;

const CarouselButton = styled.button`
  position: absolute;
  top: 45vh;
  z-index: 9;
  transform: translateY(-50%);
  background: transparent;
  border: 0;
  outline: none !important;
  svg {
    cursor: pointer;
    width: 100px;
    color: white;
    height: 100px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: rgb(0, 62, 168);
      transform: scale(1.1);
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Section2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  direction: rtl;
  max-width: 50rem;
  margin: 0 auto;
  margin-top: -5rem;
  padding: 0 0 5rem 0;
  font-family: "Assistant";
  color: #262935;
  h1 {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
  }
  h3 {
    font-size: 2.6rem;
    font-weight: 600;
    text-align: center;
  }
  p {
    font-size: 1.25rem;
    text-align: center;
    margin: 1rem 0;
  }
  a {
    color: #00f;
    text-decoration: underline;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
    h1 {
      font-size: 60px;
    }
    h3 {
      font-size: 40px;
    }
    p {
      margin: 1rem 0;
      font-size: 16px;
      width: 90%;
      line-height: 1.7rem;
      font-weight: 400;
      text-align: center;
    }
    a {
      margin-bottom: 1rem;
    }
  }
`;

const Landing = () => {
  const [isScrolled, setScrolled] = useState(false);
  const [index, setIndex] = useState(0);
  let Carousel = null;
  let Carousel2 = null;
  useEffect(() => {
    window.addEventListener("scroll", function (e) {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  return (
    <Container>
      <HeaderComponent isScrolled={isScrolled} />
      <AliceCarousel
        autoPlayDirection={"rtl"}
        mouseTracking
        ref={(el) => (Carousel = el)}
        // slideTo={index}
        startIndex={2}
        // activeIndex={0}
        // slideToIndex={index}
        infinite={true}
      >
        {SliderState.map((state) => (
          <Slider state={state} />
        ))}
      </AliceCarousel>
      <CarouselButton
        style={{ left: "0" }}
        onClick={() => Carousel.slidePrev()}
      >
        <MdChevronLeft />
      </CarouselButton>
      <CarouselButton
        style={{ right: "0" }}
        onClick={() => Carousel.slideNext()}
      >
        <MdChevronRight />
      </CarouselButton>
      {/* <Section2>
        <h1>TRUCKIBY בדרך שלכם</h1>
        <h3>הדרך שלנו ביחד משנה את כל מה שהכרתם בהובלות עד היום.</h3>
        <p>
          פנו את הדרך ל TRUCKIBY <br /> פלטפורמת ההובלות החדשנית והמתקדמת שמקשרת
          בין מובילים לבין ספקים <br />
          המעוניינים בהובלות ושינוע משטחים, מכולות או כל הובלה אחרת.
        </p>
        <Link to="/about">עוד קצת עלינו</Link>
      </Section2> */}
      <section
        style={{ direction: "rtl" }}
        className=" px-2 py-2 flex flex-col w-full justify-center items-center bg-truckiby_main "
      >
        <h1 className=" text-white text-4xl md:text-6xl font-semibold">
          איך זה עובד?
        </h1>
        <h2 className="text-white text-center text-2xl md:text-3xl">
          TRUCKIBY הופכת את תהליך העבודה שלכם לפשוט, נגיש ומשתלם מתמיד - גם
          למובילים וגם לספקים.
        </h2>
        <div className="flex mt-4 md:mt-8 flex-col  md:justify-center mx-auto w-full items-center md:flex-row relative">
          <div className="flex flex-col mx-4">
            <img
              src={part1}
              className="h-full w-full md:relative md:bottom-14"
            />
            <div className="flex items-end  justify-center mx-auto relative md:absolute bottom-0 ">
              <b className="text-white text-7xl">1</b>
              <label className="text-white mr-2 w-1/2 text-md md:text-xl md:font-medium">
                הזמנת הובלה נכנסת למערכת
              </label>
            </div>
          </div>
          <div className="flex flex-col mx-4">
            <img
              src={part2}
              className="h-full w-full md:relative md:bottom-14 md:right-3"
            />
            <div className="flex items-end  justify-center mx-auto relative md:absolute bottom-0">
              <b className="text-white text-7xl">2</b>
              <label className="text-white mr-2 w-1/2 text-md md:text-xl md:font-medium">
                קבלת הצעת מחיר תוך מספר שניות!
              </label>
            </div>
          </div>
          <div className="flex flex-col mx-4">
            <img
              src={part3}
              className="h-64 w-56 md:relative md:bottom-5 md:right-8"
            />
            <div className="flex items-end  justify-center mx-auto md:relative md:right-10">
              <b className="text-white text-7xl">3</b>
              <label className="text-white mr-2 w-1/2 text-md md:text-xl md:font-medium">
                המוביל הרלוונטי יוצר קשר מיד
              </label>
            </div>
          </div>
          <div className="flex flex-col mx-4">
            <img
              src={part4}
              className="h-full w-full md:relative md:bottom-14"
            />
            <div className="flex items-end  justify-center mx-auto relative md:absolute bottom-0">
              <b className="text-white text-7xl">4</b>
              <label className="text-white mr-2 w-1/2 text-md md:text-xl md:font-medium">
                ההובלה נסגרה המוביל יוצא לדרך!
              </label>
            </div>
          </div>
        </div>
        <div className="my-8 md:my-12">
          <ActionButton
            onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
            style={{ fontSize: "20px", padding: ".5rem 2rem" }}
            className="outline"
          >
            להזמנת הובלה עכשיו !
          </ActionButton>
        </div>
      </section>
      <section style={{ direction: "rtl" }} className="flex flex-col">
        {/* <div className="flex w-full md:justify-around flex-col md:flex-row md:p-16">
          <div>
            <img src={cat1} />
          </div>
          <div className="text-truckiby_black px-2 md:px-0">
            <h1 className="text-3xl md:text-5xl ">מחפש פתרונות הובלה?</h1>
            <h2 className="text-2xl md:text-3xl mb-12 mt-8">
              המובילים של TRUCKIBY זמינים להובלה בזמן אמת!
            </h2>
            <div className="flex items-center my-4">
              <img src={price} className="w-16 h-16 md:w-20 md:h-20 ml-4" />
              <label className="text-xl">מחירים תחרותיים</label>
            </div>
            <div className="flex items-center my-4">
              <img
                src={delievryTruck}
                className="w-16 h-16 md:w-20 md:h-20 ml-4"
              />
              <label className="text-xl">הובלה ושינוע מהיום להיום!</label>
            </div>
            <div className="flex items-center my-4">
              <img src={trolley} className="w-16 h-16 md:w-20 md:h-20 ml-4" />
              <label className="text-xl">פתרונות מתקדמים לכל סוג הובלה</label>
            </div>
            <div className="flex my-8 text-3xl justify-center md:justify-start">
              <ActionButton
                onClick={() =>
                  (window.location.href = "/404?https://app.truckiby.com/")
                }
                style={{ padding: ".2rem 3rem" }}
                className="main outline-none focus:outline-none"
              >
                להזמנת הובלה
              </ActionButton>
            </div>
          </div>
        </div> */}

        {/* <div className="flex w-full md:justify-around flex-col-reverse md:flex-row">
          <div className="text-truckiby_black px-2 md:px-0">
            <h1 className="text-3xl md:text-5xl ">בעל משאית?</h1>
            <h2 className="text-2xl md:text-3xl mb-12 mt-8">
              הדרך הביתה מלאה בלקוחות פוטנציאליים!
            </h2>
            <div className="flex items-center my-4">
              <img src={trucking} className="w-16 h-16 md:w-20 md:h-20 ml-4" />
              <label className="text-xl">
                פניות שמתאימות לסוג ההובלות שאתם מבצעים
              </label>
            </div>
            <div className="flex items-center my-4">
              <img src={location} className="w-16 h-16 md:w-20 md:h-20 ml-4" />
              <label className="text-xl">
                פניות המתחשבות במסלול ובמיקום שלכם
              </label>
            </div>
            <div className="flex items-center my-4">
              <img src={chat} className="w-16 h-16 md:w-20 md:h-20 ml-4" />
              <label className="text-xl">תקשורת נוחה בווטסאפ ובמייל</label>
            </div>
            <div className="flex my-8 text-3xl justify-center md:justify-start">
              <ActionButton
                onClick={() =>
                  (window.location.href = "/404?https://app.truckiby.com/")
                }
                style={{ padding: ".2rem 3rem" }}
                className="main outline-none focus:outline-none"
              >
                לקבלת הובלות בדרך שלך
              </ActionButton>
            </div>
          </div>
          <div>
            <img src={cat2} />
          </div>
        </div> */}
      </section>
      <Partners
        className="w-full relative py-2 flex my-4 flex-col"
        style={{
          top: "85px",
        }}
      >
        <AliceCarousel
          id="feedbacks"
          buttonsDisabled={true}
          autoPlayDirection={"ltr"}
          ref={(el) => (Carousel2 = el)}
          startIndex={2}
          autoPlayStrategy="none"
          autoPlayInterval={2000}
          animationDuration={1000}
          // animationType="fadeout"
          responsive={responsive}
          autoPlay={true}
          infinite={true}
        >
          <div
            style={{
              width: window.innerWidth > 768 ? "500px" : "350px",
              direction: "rtl",
            }}
            className="p-4 flex mx-4 relative text-right "
          >
            <div className=" p-4 m-4 my-auto flex flex-col rounded-md shadow-2xl relative">
              <div
                className=" text-9xl text-gray-300 absolute transform -translate-x-1/2"
                style={{ fontFamily: "cursive" }}
              >
                "
              </div>
              <div className="p-8">
                <p className="text-base z-10 relative">
                  סיוון היקר, ראיתי לנכון לכתוב לך כמה מילות תודה.
                  <br />
                  חברתנו ״אורניט בע״מ״ עובדת מזה זמן מה עם TRUCKIBY עלי לציין
                  שהעבודה נעשית בצורה מקצועית יותר.
                  <br />
                  הפתרון של TRUCKIBY נותן לנו מענה מיידי ולכן מומלץ בחום.
                  <br />
                  אנשים מדהימים שכיף לעבוד איתם
                </p>
                <h2 className=" font-bold text-sm">רונן הירש</h2>
                <label className=" text-sm text-gray-800">
                  מנהל רכש ולוגיסטיקה, אורניט בע״מ
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              width: window.innerWidth > 768 ? "500px" : "350px",
              direction: "rtl",
            }}
            className="p-4 flex mx-4 relative text-right"
          >
            <div className=" p-4 m-4 my-auto flex flex-col rounded-md shadow-2xl relative">
              <div
                className=" text-9xl text-gray-300 absolute transform -translate-x-1/2"
                style={{ fontFamily: "cursive" }}
              >
                "
              </div>
              <div className="p-8">
                <p className="text-lg z-10 relative">
                  לסיון והחברים.
                  <br />
                  כבר למעלה משנתיים שאני מקבל מענה מתאים לכל צורת הובלה שאני
                  צריך ,<br />
                  החל מרכבים קטנים ועד הובלות טריילר.
                  <br />
                  תמיד במחירים הוגנים , במהירות ובמקצועיות ולכן אני מודה לכם על
                  כך ומאחל לכם הצלחה גדולה מכל הלב.
                  <br />
                  בברכה.
                  <br />
                </p>
                <h2 className=" font-bold text-base">לואי</h2>
                <label className=" text-base text-gray-800">
                  מנכ"ל ע.ב.ל מתכות
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              width: window.innerWidth > 768 ? "500px" : "350px",
              direction: "rtl",
            }}
            className="p-4 flex mx-4 relative text-right "
          >
            <div className=" p-4 m-4 my-auto flex flex-col rounded-md shadow-2xl relative">
              <div
                className=" text-9xl text-gray-300 absolute transform -translate-x-1/2"
                style={{ fontFamily: "cursive" }}
              >
                "
              </div>
              <div className="p-8">
                <p className="text-lg z-10 relative">
                  שמי אריק , אני מוביל מאשדוד,
                  <br />
                  ובעזרת TRUCKIBY הצלחתי בשנתיים האחרונות להגדיל את צי המשאיות
                  שברשותי. אני נעזר בTRUCKIBY לא רק לעבודה אלא גם בתקופות של
                  ביקוש יתר אני משתמש בהם כאילו אני הלקוח , והם מחברים אלי נהגי
                  משאית לעבודה יומית.
                  <br />
                  מרווחי הרווח שלהם כל כך קטנים שאין לי כל סיבה "לדלג" עליהם.
                  <br />
                  ממליץ בחום.
                  <br />
                </p>
                <h2 className=" font-bold text-base">אריק</h2>
                <label className=" text-base text-gray-800">
                  צ'וצ'י הובלות
                </label>
              </div>
            </div>
          </div>
        </AliceCarousel>
        <div
          style={{ transform: "translateY(-90%)" }}
          className="flex relative transform flex-wrap md:flex-nowrap top-80 md:top-0 items-center md:-translate-y-full w-4/5 m-auto justify-around"
        >
          <img
            src={partner1}
            alt=""
            style={{ width: "132px", height: "132px" }}
          />
          <img
            src={partner2}
            alt=""
            style={{ width: "105px", height: "105px" }}
          />
          <img
            src={partner3}
            alt=""
            style={{ width: "198px", height: "48px" }}
          />
          <img
            src={partner4}
            alt=""
            style={{ width: "186px", height: "132px" }}
          />
          <img
            src={partner5}
            alt=""
            style={{ width: "132px", height: "132px" }}
          />
          <img
            src={partner6}
            alt=""
            style={{ width: "132px", height: "132px" }}
          />
        </div>
      </Partners>
      <section style={{ direction: "rtl" }} className="relative">
        <img src={forest} className="w-full h-56 md:h-full" />
        <div
          className="absolute top-1/2 transform -translate-y-1/2 text-center justify-center flex flex-col w-full text-white "
          style={{ zIndex: "1" }}
        >
          <h1 className="text-3xl md:text-7xl m-0">
            המהפכה הירוקה
            <br className="md:hidden" /> כבר כאן
          </h1>
          <h2 className="text-lg md:text-4xl m-0 md:my-2">
            כל העולם עובר לחסכון בדלק ולשימוש חכם יותר באנרגיות קיימות.
          </h2>
          <h2 className="text-lg m-0 md:text-4xl">
            הפתרון של TRUCKIBY חוסך אלפי שעות נסיעה מיותרות.
          </h2>
        </div>
      </section>

      <ContactUs />
      {/* <section
        id="contact"
        className="flex flex-col md:flex-row-reverse py-8 px-3 justify-center items-center md:p-16"
        style={{ direction: "rtl" }}
      >


        <div className="flex flex-col px-5">
          <label className="flex text-2xl">
            השאירו לנו פרטים ונשמח ליצור אתכם קשר מיד בכל שאלה או פניה.
          </label>
          <input
            className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
            placeholder="שם"
          />
          <input
            className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
            placeholder="מספר טלפון"
          />
          <input
            className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
            placeholder="הודעה"
          />
          <div className="w-full justify-center items-center flex mt-4">
            <ActionButton style={{ padding: ".3rem 2rem" }} className="main">
              שליחת הודעה
            </ActionButton>
          </div>
        </div>


        {/* <span className="w-full bg-truckiby_gray_2 block h-1" /> *ש/}
        <div className="flex w-full md:w-min     border-t-2 pt-4 mt-4 md:mt-0 md:border-t-0 md:ml-8 md:pt-0 md:pl-8 md:border-l-2 border-truckiby_gray_2  flex-col justify-center md:justify-start items-center md:items-start">
          <a
            href="tel:+972723921889"
            className="text-3xl items-center flex font-normal"
          >
            <MdPhone className="ml-2" /> 072-392-1889
          </a>
          <label
            className="text-truckiby_main text-5xl font-semibold mt-4"
            style={{ fontFamily: "Alfa Slab One" }}
          >
            TRUCKIBY
          </label>
          <label className="text-3xl hidden md:block ">
            משנעים את עולם ההובלות
          </label>
        </div>
      </section> */}

      <footer className="bg-truckiby_black px-8 flex p-4 md:p-6 justify-center items-center flex-col md:flex-row md:justify-between">
        <div className="flex">
          <img
            onClick={() =>
              (window.location.href = "https://www.facebook.com/trackiby")
            }
            src={facebook}
            className="mx-2"
            alt={"Facebook"}
          />
          <img
            onClick={() =>
              (window.location.href = "https://www.linkedin.com/in/sivanmaron")
            }
            src={linkedin}
            className="mx-2"
            alt={"Linkedin"}
          />
        </div>
        <div className="text-white text-md md:text-lg mt-2 md:mt-0">
          <span className="font-semibold">2020 כל הזכויות שמורות</span>
          <span className="mx-1 font-bold">TRUCKIBY</span>
          <span className="font-semibold">©</span>
        </div>
      </footer>
    </Container>
  );
};

const SliderState = [
  {
    title: "עולם ההובלה משתנה.",
    secondLine: "מובילים מהיום להיום משאיות משטחים ומנופים. כמו מונית.",

    img: image3,
  },
  {
    title: "מאות מובילים עובדים איתנו ומקבלים תשלום מיד בסוף ההובלה !!!!",
    img: image2,
  },
  {
    title: "מזמינים הובלה כמו מונית !!",
    secondLine: "הכל מהיום להיום.",
    img: image1,
  },
];

export default Landing;
