import React from "react";
import styled from "styled-components";
import { ActionButton } from "../../sharedStyle";

export const Slider = ({ state }) => {
  return (
    <SliderContainer backgroundImage={state.img}>
      <div style={{ display: "grid" }}>
        <h1 style={{ zIndex: "10", color: "rgba(0,0,0,0.7)" }}>
          {state.title}
        </h1>
        <h1 style={{ zIndex: "11", filter: "none", background: "none" }}>
          {state.title}
          < br />
          {state.secondLine}
        </h1>
      </div>
      <div id="buttons" className="button">
        <ActionButton
          onClick={() => (window.location.href = "/404?https://app.truckiby.com/")} // TODO remove /404?
          className="main p-5"
        >
          להזמנת הובלה עכשיו!
        </ActionButton>
        <ActionButton
          onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
          className="outline p-5"
        >
          קבלת הובלה למובילים
        </ActionButton>
      </div>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-right:10px;
    color: white;
    width: 80%;
    font-size: 5rem;
    font-weight: 700;
    direction: rtl;
    text-align: right;
    white-space: normal;
    max-height: 25rem;
    overflow: hidden;
    text-overflow: ellipsis; 
    filter: blur(10px);
    grid-column: 1;
    grid-row: 1;
    @media (max-width: 768px) {
      width: 60%;
    }
  }

  div {
    margin-right: 10%;
    position: absolute;
    top: 50%;
    white-space: pre-line;
    transform: translateY(-60%);
  }

  #buttons {
    top: 80%;

    @media (max-width: 768px) {
      top: 80%;
      display: flex;
      flex-direction: column;
      margin: 0 30vw;
      width: 40vw;
      
      button {
        margin: 0.2rem 0;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 40px;
      margin-right: 0px;
      color: white;
      width: 100%;
      font-weight: 700;
      direction: rtl;
      text-align: right;
      filter: blur(10px);
      grid-column: 1;
      grid-row: 1;
    }
  }
`;