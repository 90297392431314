import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { ActionButton } from "../../sharedStyle";
import { MdMenu, MdClose } from "react-icons/md";
import { useEffect, useState } from "react";

const Header = styled.div`
  position: sticky;
  top: 0;
  background: transparent;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  .background {
    background: #262935;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 1;
    z-index: 1;
    opacity: ${(props) => (props.isScrolled ? "1" : "0")};
    transition: opacity 1s;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled(Link)`
  font-family: Alfa Slab One;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
`;

const Redirector = styled(NavLink)`
  font-family: "Assistant";
  margin-right: 5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: white;
  padding: 5px 5px 7px 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &.active {
    color: #0059f0;
    border-bottom: 4px solid #0059f0;
    &:hover {
      border-radius: 0px;
      background: transparent;
    }
  }
`;
const RedirectorLink = styled(Link)`
  font-family: "Assistant";
  margin-right: 5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  color: white;
  padding: 5px 5px 7px 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &.active {
    color: #0059f0;
    border-bottom: 4px solid #0059f0;
    &:hover {
      border-radius: 0px;
      background: transparent;
    }
  }
`;

export const HeaderComponent = ({ isScrolled }) => {
  const [isMenu, setMenu] = useState(false);

  useEffect(() => {
    if (isMenu) {
      document.getElementsByTagName("BODY")[0].style.overflowY = "hidden";
    } else {
      document.getElementsByTagName("BODY")[0].style.overflowY = "auto";
    }
  }, [isMenu]);
  return (
    <>
      <Header isScrolled={isScrolled}>
        <div className="background" />
        <div style={{ zIndex: "2" }}>
          <ActionButton
            onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
            className="second"
          >
            {" "}
            התחברות
          </ActionButton>
          <ActionButton
            onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
            className="main"
          >
            הרשמה
          </ActionButton>
        </div>
        <div style={{ zIndex: "2" }}>
          <RedirectorLink
            onClick={() => {
              try {
                document.getElementById("contact").scrollIntoView();
              } catch (err) {
                window.location.href = "/#contact";
              }
            }}
            to="#contact"
          >
            צור קשר
          </RedirectorLink>
          <Redirector activeClassName="active" to="/about">
            קצת עלינו
          </Redirector>
          <Logo to="/">Truckiby</Logo>
        </div>
      </Header>
      <div className="md:hidden justify-between p-2 items-center flex-row-reverse z-10 flex bg-truckiby_black sticky top-0">
        <Logo to="/">Truckiby</Logo>
        <div className="flex  h-full flex-row-reverse justify-between">
          <ActionButton
            onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
            className="second "
          >
            {" "}
            התחברות
          </ActionButton>
          <ActionButton
            onClick={() => (window.location.href = "/404?https://app.truckiby.com/")}
            className="main "
          >
            הרשמה
          </ActionButton>
          <button
            onClick={() => {
              setMenu(true);
            }}
          >
            <MdMenu className="text-white w-8 h-8" />
          </button>
        </div>
      </div>
      {isMenu && (
        <div className="bg-truckiby_black fixed top-0 z-50 left-0 right-0 bottom-0 w-full ">
          <div className="flex items-center p-2 w-full justify-between">
            <button
              onClick={() => {
                setMenu(false);
              }}
            >
              <MdClose className="text-white w-8 h-8" />
            </button>
            <Logo to="/">Truckiby</Logo>
          </div>
          <div className="flex flex-col text-white text-2xl mt-4">
            <a
              // href="#contact"
              onClick={() => {
                try {
                  document.getElementById("contact").scrollIntoView();
                } catch (err) {
                  window.location.href = "/#contact";
                }
                setMenu(false);
              }}
              className="text-center p-4"
            >
              צור קשר
            </a>
            <a href="/about" className="text-center p-4">
              קצת עלינו
            </a>
            <div
              onClick={() =>
                (window.location.href = "/404?https://app.truckiby.com/")
              }
              className="text-center p-4"
            >
              מובילים
            </div>
            <div
              onClick={() =>
                (window.location.href = "/404?https://app.truckiby.com/")
              }
              className="text-center p-4"
            >
              ספקים
            </div>
            <div className="w-2/5 flex flex-col-reverse m-auto mt-4 ">
              <ActionButton
                onClick={() =>
                  (window.location.href = "/404?https://app.truckiby.com/")
                }
                style={{
                  fontSize: "16px",
                  padding: ".3rem",
                }}
                className="second "
              >
                {" "}
                התחברות
              </ActionButton>
              <ActionButton
                onClick={() =>
                  (window.location.href = "/404?https://app.truckiby.com/")
                }
                style={{
                  marginBottom: "10px",
                  fontSize: "16px",
                  padding: ".3rem",
                }}
                className="main "
              >
                הרשמה
              </ActionButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
