import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "../pages/landing";
import { About } from "../pages/about";
import { E404 } from "../pages/404";

// import About from '../pages/About'

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/404" component={E404} />
        <Route component={Landing} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
