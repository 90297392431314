import React, { useState } from "react";
import { MdPhone } from "react-icons/md";
import { ActionButton } from "../../sharedStyle";
import emailjs from "emailjs-com";
import { serviceId, templateId, userId } from "./config";

export const ContactUs = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState("");

  const clearForm = () => {
    setName("");
    setPhone("");
    setMessage("");
  };

  const validateForm = () => {
    if (name && phone && message) {
      setFormError("");
      return true;
    } else {
      setFormError("יש למלא את כל הפרטים.");
      return false;
    }
  };
  const onSubmit = (e) => {
    console.log("ksjd" + e.target);
    e.preventDefault();
    if (validateForm()) {
      emailjs.sendForm(serviceId, templateId, e.target, userId).then(
        (result) => {
          setFormError("ההודעה נשלחה בהצלחה");

          clearForm();
          e.target.reset();
          console.log(result.text);
        },
        (error) => {
          setFormError("הייתה בעיה בעת שליחת האימייל.");

          console.log(error.text);
        }
      );
    }
  };

  return (
    <section
      id="contact"
      className="flex flex-col md:flex-row-reverse py-8 px-3 justify-center items-center md:p-16"
      style={{ direction: "rtl" }}
    >
      <form className="flex flex-col px-5" onSubmit={onSubmit}>
        <label className="flex text-2xl">
          השאירו לנו פרטים ונשמח ליצור אתכם קשר מיד בכל שאלה או פניה.
        </label>
        <input
          className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
          placeholder="שם"
          value={name}
          name={"from_name"}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
          placeholder="מספר טלפון"
          value={phone}
          name={"phone"}
          onChange={(e) => setPhone(e.target.value)}
        />
        <input
          className="my-2 md:my-3 border-b outline-none focus:outline-none border-truckiby_gray_2"
          placeholder="הודעה"
          value={message}
          name={"message"}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="w-full justify-center items-center flex mt-4">
          <ActionButton
            type="submit"
            style={{ padding: ".3rem 2rem" }}
            className="main"
          >
            שליחת הודעה
          </ActionButton>
        </div>
        {formError && (
          <label className="flex text-xl text-red-600 m-auto mt-2">
            {formError}
          </label>
        )}
      </form>

      {/* <span className="w-full bg-truckiby_gray_2 block h-1" /> */}
      <div className="flex w-full md:w-min     border-t-2 pt-4 mt-4 md:mt-0 md:border-t-0 md:ml-8 md:pt-0 md:pl-8 md:border-l-2 border-truckiby_gray_2  flex-col justify-center md:justify-start items-center md:items-start">
        <a
          href="tel:+972723921889"
          className="text-3xl items-center flex font-normal"
        >
          <MdPhone className="ml-2" /> 072-392-1889
        </a>
        <label
          className="text-truckiby_main text-5xl font-semibold mt-4"
          style={{ fontFamily: "Alfa Slab One" }}
        >
          TRUCKIBY
        </label>
        <label className="text-3xl hidden md:block ">
          משנעים את עולם ההובלות
        </label>
      </div>
    </section>
  );
};
